import React from 'react'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit = event => {
    event.preventDefault()
    const form = event.target
    console.log(
      encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    )
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(res => {
        if (res.ok) return res
        else throw Error(res.statusText)
      })
      .then(() =>
        document.getElementById('contact-form').classList.add('Form--success'),
      )
      .catch(() =>
        document.getElementById('contact-form').classList.add('Form--failure'),
      )
  }

  render() {
    return <form
      id='contact-form'
      className='Form'
      name='contact'
      method='post'
      data-netlify='true'
      onSubmit={this.handleSubmit}
    >
      <input type='hidden' name='form-name' value='contact' />
      <div className='field half first'>
        <label htmlFor='name'>Name</label>
        <input
          name='name'
          id='name'
          type='text'
          required
          onChange={this.handleChange}
        />
      </div>
      <div className='field half'>
        <label htmlFor='email'>Email</label>
        <input
          name='email'
          id='email'
          type='text'
          required
          onChange={this.handleChange}
        />
      </div>
      <div className='field'>
        <label htmlFor='message'>Nachricht</label>
        <textarea
          name='message'
          id='message'
          rows='6'
          required
          onChange={this.handleChange}
        />
      </div>
      <ul className='actions'>
        <li>
          <button name='submit' type='submit' className='special'>
            Senden
          </button>
        </li>
        <li>
          <input name='reset' type='reset' value='Leeren' />
        </li>
      </ul>
    </form>
  }
}