import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Mailchimp from './Mailchimp'
import ContactForm from './ContactForm'

export default function Contact() {
  const { options: { options: { general: { contact: { address, email, mobile } } } } } = useStaticQuery(graphql`
    query ContactQuery {
      options: wp {
        options {
          general {
            contact {
              address
              email
              mobile
            }
          }
        }
      }
    }
  `)

  return (
    <section id='contact'>
      <div className='inner'>
        <section className='split split--left'>
          <section>
            <h2>Wir freuen uns über Ihre Nachricht!</h2>
            <ContactForm />
          </section>
          <section>
            <h2>Zum E-Mail Newsletter anmelden</h2>
            <Mailchimp />
          </section>
        </section>
        <section className='split'>
          <section>
            <div className='contact-method'>
              <span className='icon alt fa-envelope' />
              <h3>Email</h3>
              <span>{email}</span>
            </div>
          </section>
          <section>
            <div className='contact-method'>
              <span className='icon alt fa-phone' />
              <h3>Mobil</h3>
              <span>{mobile}</span>
            </div>
          </section>
          <section>
            <div className='contact-method'>
              <span className='icon alt fa-home' />
              <h3>Adresse</h3>
              <span
                dangerouslySetInnerHTML={{
                  __html: address,
                }}
              />
            </div>
          </section>
        </section>
      </div>
    </section>
  )
}
