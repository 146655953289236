import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Menu from './Menu'

export default function Header() {
  return (
    <header id="header" className="alt">
      <div className="navigation">
        <Link to="/" className="logo">
          <strong>Wo der Pfeffer wächst</strong>
        </Link>
        <Menu />
      </div>
      <a href="https://www.lifeearth-reisen.at/">
        <StaticImage
          src="../assets/images/life-earth-reisen.png"
          alt="Life Earth Reisen"
          layout="fixed"
          objectFit="contain"
          height={36}
          placeholder="none"
          style={{ display: 'block', marginTop: '0.75rem' }}
        />
      </a>
    </header>
  )
}
