import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export default function Footer() {
  const {
    options: {
      options: {
        general: {
          social: { facebook, instagram },
        },
      },
    },
  } = useStaticQuery(graphql`
    query SocialQuery {
      options: wp {
        options {
          general {
            social {
              facebook
              instagram
            }
          }
        }
      }
    }
  `)

  return (
    <footer id="footer">
      <div className="inner">
        <section>
          <ul className="icons">
            <li>
              <a
                href={`https://www.facebook.com/${facebook}`}
                className="icon alt fa-facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.flickr.com/people/181886267@N04/"
                className="icon alt fa-flickr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="label">Flickr</span>
              </a>
            </li>
            <li>
              <a
                href={`https://www.instagram.com/${instagram}`}
                className="icon alt fa-instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>

          <ul className="copyright">
            <li>&copy; Wo der Pfeffer wächst</li>
            <li>
              Entwickelt von <a href="https://re1.at">re1</a> basierend auf
              Forty von <a href="https://html5up.net">HTML5 UP</a>
            </li>
          </ul>
        </section>
        <section className="Footer-Logo">
          <span>Ein Angebot von</span>
          <StaticImage
            src="../assets/images/life-earth-reisen.png"
            alt="Life Earth Reisen"
            layout="constrained"
            objectFit="contain"
            width={250}
            placeholder="none"
          />
        </section>
      </div>
    </footer>
  )
}
