import addToMailchimp from 'gatsby-plugin-mailchimp'
import React from 'react'

export default class Mailchimp extends React.Component {
  // Since `addToMailchimp` returns a promise, you
  // can handle the response in two different ways:

  // Note that you need to send an email & optionally, listFields
  // these values can be pulled from React state, form fields,
  // or wherever.  (Personally, I recommend storing in state).

  state = {
    name: null,
    email: null,
  }

  handleChange = event => {
    this.setState({
      [`${event.target.name}`]: event.target.value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()

    const form = event.currentTarget

    addToMailchimp(this.state.email, this.state)
      .then(({ msg, result }) => {
        if (result !== 'success') {
          throw msg
        }
        console.log(msg)
        form.classList.add('Form--success', 'Form--success--mailchimp')
      })
      .catch(err => {
        console.log(err)
        err.indexOf('is already subscribed') > -1
          ? form.classList.add('Form--warning--mailchimp--duplicate')
          : form.classList.add('Form--failure', 'Form--failure--mailchimp')
      })
  }

  render() {
    return (
      <form id="mailchimp-form" onSubmit={this.handleSubmit} className="Form">
        <input
          type="email"
          onChange={this.handleChange}
          placeholder="E-Mail"
          name="email"
          required
        />
        <ul className="actions">
          <li>
            <button name="submit" type="submit" className="special">
              Abonnieren
            </button>
          </li>
        </ul>
      </form>
    )
  }
}
