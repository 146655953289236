import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

export default function Menu() {
  const {
    options: {
      options: {
        general: {
          galleryLink
        },
      },
    },
  } = useStaticQuery(graphql`
    query GalleryLinkQuery {
      options: wp {
        options {
          general {
            galleryLink
          }
        }
      }
    }
  `)

  return (
    <div className='Menu'>
      {/* Open menu for small screen widths */}
      <label className='Menu-Button' htmlFor='menu-state'>
        Menü <span className='icon fa-navicon' />
      </label>

      <input className='Menu-State' id='menu-state' type='checkbox' />

      <div className='Menu-Content'>
        <label className='Menu-Overlay' htmlFor='menu-state' />
        <nav>
          <ul className='Menu-Links'>
            <li>
              <Link to='/#one'>Reisen</Link>
            </li>
            <li>
              <Link to='/about'>About</Link>
            </li>
            <li>
              <Link to='/angebot'>Angebot</Link>
            </li>
            <li>
              <a
                href={galleryLink}
                target='_blank'
                rel='noopener noreferrer'
              >
                Galerie
              </a>
            </li>
            <li>
              <Link to='/impressum'>Impressum</Link>
            </li>
          </ul>
        </nav>

        {/* Close menu for small screen widths */}
        <label className='Menu-Close' htmlFor='menu-state'>
          <span className='icon fa-close' />
        </label>
      </div>
    </div>
  )
}
