import { graphql, useStaticQuery } from 'gatsby'
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo'
import React from 'react'
import '../assets/scss/main.scss'
import Contact from './Contact'
import Footer from './Footer'
import Header from './Header'
import ScrollToTopButton from './ScrollToTopButton'
import Wrapper from './Wrapper'

export default function Layout({ children }) {
  const {
    wp: { seo },
  } = useStaticQuery(graphql`
    query SiteInfoQuery {
      wp {
        seo {
          contentTypes {
            post {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
            }
            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            twitter {
              username
            }
          }
        }
      }
    }
  `)

  return (
    <SEOContext.Provider value={{ global: seo }}>
      <Wrapper>
        <Header />
        {children}
        <Contact />
        <Footer />
        <ScrollToTopButton />
      </Wrapper>
    </SEOContext.Provider>
  )
}
