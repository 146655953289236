import React from 'react'

export default class ScrollToTopButton extends React.Component {
  handleScroll() {
    if (window.scrollY >= window.innerHeight * 0.5) {
      if (
        document
          .getElementById('scroll-top')
          .classList.contains('scroll-top--hidden')
      ) {
        document
          .getElementById('scroll-top')
          .classList.remove('scroll-top--hidden')
      }
    } else {
      document.getElementById('scroll-top').classList.add('scroll-top--hidden')
    }
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll)
  }

  render() {
    return <button
      aria-label='Scroll to top'
      id='scroll-top'
      className='seamless scroll-top scroll-top--hidden icon fa-angle-double-up'
      onClick={() => {
        window.scrollTo(0, 0)
      }}
    />
  }
}